import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "info-title-bottom" }
const _hoisted_2 = { class: "info-light-title" }
const _hoisted_3 = {
  key: 0,
  class: "status-on"
}
const _hoisted_4 = {
  key: 1,
  class: "status-off"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("label", _hoisted_2, _toDisplayString(_ctx.$wordList.OrderDevice), 1)
    ]),
    _createVNode(_component_list, {
      headers: _ctx.headers,
      update: _ctx.updateToList,
      "list-data": _ctx.devicesList,
      "request-data": false,
      showCheck: false,
      action: false
    }, {
      TypeSlot: _withCtx((data) => [
        _createVNode("div", null, [
          _createVNode("img", {
            class: "margin-top15px",
            src: _ctx.DeviceTypeIcon[data.scope.Type],
            ref: _ctx.setAllImg
          }, null, 8, ["src"])
        ])
      ]),
      StatusSlot: _withCtx((data) => [
        (Number(data.scope.Status) === _ctx.access.statusOn)
          ? (_openBlock(), _createBlock("div", _hoisted_3))
          : _createCommentVNode("", true),
        (Number(data.scope.Status) === _ctx.access.statusOff)
          ? (_openBlock(), _createBlock("div", _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["headers", "update", "list-data"])
  ]))
}