
import {
    defineComponent, PropType, ref, watch
} from 'vue';
import { list, ListHeaderItem } from '@/components/common/list';
import { DevicesData } from '@/views/pm/apartment/info/all.type';
import { access } from '@/data';
import { limitErrorImg } from '@/methods/basicFun';

const headers: Array<ListHeaderItem> = [{
    name: 'Type',
    label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
    type: 'customize'
}, {
    name: 'Location',
    label: WordList.TabelPersonDeviceInHtmlLocation
}, {
    name: 'MAC',
    label: WordList.TabelPersonDeviceInHtmlMAC
}, {
    name: 'Status',
    label: WordList.MulListUpdateListTanleStatus,
    type: 'customize'
}];

export default defineComponent({
    components: {
        list
    },
    props: {
        devicesList: {
            type: Object as PropType<DevicesData | []>,
            required: true
        }
    },
    setup(props) {
        const imgItem = ref<Array<any>>([]);
        const setAllImg = (el: any) => {
            if (el) {
                limitErrorImg(el);
                imgItem.value.push(el);
            }
        };

        const updateToList = ref(0);
        watch(
            () => props.devicesList,
            () => {
                updateToList.value += 1;
            }
        );

        const DeviceTypeIcon = {
            0: require('@/assets/image/IconDeviceTypeStair.png'),
            1: require('@/assets/image/IconDeviceTypeDoor.png'),
            2: require('@/assets/image/IconDeviceTypeIndoor.png'),
            3: require('@/assets/image/IconDeviceTypeManagement.png'),
            4: require('@/assets/image/IconDeviceTypeWall.png'),
            50: require('@/assets/image/IconDeviceTypeAccess.png')
        };

        return {
            updateToList,
            headers,
            DeviceTypeIcon,
            access,
            setAllImg
        };
    }
});
